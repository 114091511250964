.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // Material-UI font family
}

.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.filters-wrapper {
  display: flex;
  column-gap: 8px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 8px;
  }
}
.search-baar{
  width:400px;
}

.search-box {
  padding: 5px;
}

.selectable-button-container-style {
  padding: 1px;
  width: 150px;
}

.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;
  background-color: var(--primary-blue);
  border-radius: 5px;
  color: white !important;
}

.add-blogs-button {
  color: white !important;
  font-weight: bolder;
  width: 100%;
  border-radius: 2px !important;
  padding: 10px;
}

.excel-button-container-style {
  padding: 6px 14px;
  background-color: var(--excel-green);
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);
}