.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    flex: 1;
  }
  
  .content-container {
    width: 100%;
    display: flex;
    column-gap: 50px;
    row-gap: 20px;
    flex-wrap: wrap;
    .input-wrapper {
      flex: 1;
      &:nth-child(1) {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-top: 0;
      }

    }
    .radio-wrapper-text {
      font-size: 14px;
      font-weight: 500;
      min-width: 200px;
      width: 200px;
  
      .radio-wrapper-sub-text {
        color: red;
      }
    }
    .radio-input {
      display: flex;
      gap: 1rem;
      margin-top: 0.5rem;
      min-width: 300px;
      flex: 1 1;
      .radio-sub-input {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
  
  .accordion-container {
    flex-direction: column;
    display: table-cell;
  
    .heading-wrapper {
      display: flex;
      background-color: #edf1f5;
      padding: 5px;
      .heading-text {
        flex: 1;
        font-weight: 600;
        &:nth-child(1) {
          flex: none;
          width: 200px;
          margin-left: 3rem;
        }
      }
    }
    .accordion-wrapper {
      margin-top: 12px;
      background: red;
    }
  
    button {
      background-color: #f9fbff;
      p {
        padding-left: 2rem;
      }
    }
    h2 + div {
      background-color: #fff9f9;
      padding-left: 2rem;
    }
  
    .heading {
      color: #007abe;
      font-weight: 600;
      cursor: pointer;
    }
    .check-box-container:first-of-type {
      margin-top: 1.2rem;
    }
    .check-box-container {
      display: flex;
      &:not(:first-child) {
        margin-top: 16px;
      }
  
      .check-box-text {
        width: 200px;
        font-weight: 600;
      }
  
      .check-box-sub-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 1rem;
        row-gap: 1rem;
        .check-box {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancel-container {
      color: var(--primary);
      text-decoration: none;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    .accordion-container {
      // display: inline-table;
      display: flex;
  
      .heading-wrapper {
        display: flex;
        background-color: #edf1f5;
        padding: 5px;
      }
      .accordion-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        background: red;
      }
  
      button {
        background-color: #f9fbff;
        p {
          padding-left: 2rem;
        }
      }
      h2 + div {
        background-color: #fff9f9;
        // padding-left: 2rem;
        padding: 1rem 1rem 1rem 2rem;
      }
  
      .heading {
        color: var(--primary);
        font-weight: 600;
        cursor: pointer;
      }
      .check-box-container:first-of-type {
        margin-top: 1.2rem;
      }
      .check-box-container {
        display: flex;
        &:not(:first-child) {
          margin-top: 16px;
        }
  
        .check-box-text {
          width: 200px;
          font-weight: 600;
        }
  
        .check-box-sub-container {
          flex: 1;
          display: flex;
          align-items: center;
          column-gap: 1rem;
          .check-box {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .action-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cancel-container {
        color: var(--primary);
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .container {
      padding: 15px;
    }
  
    .accordion-container {
      display: flex;
  
      button {
        background-color: #f9fbff;
        p {
          padding-left: 2rem;
        }
      }
      h2 + div {
        background-color: #fff9f9;
        padding: 1rem 1rem 1rem 2rem;
      }
  
      .check-box-container {
        display: flex;
        gap: 12px;
        flex-direction: column;
        &:not(:first-child) {
          margin-top: 16px;
        }
        .check-box-sub-container {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 1rem;
          row-gap: 1rem;
          .check-box {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  
  .action-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    align-items: center;
    margin-top: 20px;
}
.selectable-button-container-style{
  width: 400px;
}
.input-wrapper label{
  margin-bottom: 10px;
} 