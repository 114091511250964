.header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.formik{
    width: 97%;
    margin: 0px auto;
}

.wrapper{
    width: 100%;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
}

.first-container,
.second-container {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.first-container {
    margin-left: 10px;
}

.second-container {
    margin-right: 10px;
}

.input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    align-items: center;
}


.select-desin {
    width: 400px;
    display: flex;
    flex-direction: column;
}

.text-area{
    min-width: 400px;
    min-height: 200px;
}


.action-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: white;
}

.primary-button-container-style {
    background-color: var(--primary-blue);
    padding: 10px 40px;
    color: white;
    border-radius: 4px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.Heading{
    margin-top: 10px;
    padding: 4px;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 10px;
}
.Heading h1{
    color: var(--primary-blue);
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    margin-left: 40px;
}

.json-formater{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.left-side, .right-side{
    width: 45%;
    display: flex;
    flex-direction: column;
}