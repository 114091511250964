.heading {
    padding: 8px 8px 0 8px;
}

.selectable-button-container-style{
    width: 400px;
}


.header-wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    min-height: 550px;
    border-radius: 8px;
}

.action-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    align-items: center;
    margin-top: 20px;
}


.errors {
    color: red;
    font-size: 13px;
}

.blank-error{
    margin-right: 7px;
    font-size: 18px;
}

.select-wraapper label {
    margin-bottom: 10px;
}



