.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 40px;
  margin-top: 10px;
  border-radius: 5px;
}

.containerMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
}
.line {
  border: 1px solid #ebebeb;
  margin: 40px 0;
}

.uploaded-file-wrapper {
  position: relative;
  width: 150px;

  .image-delete-icon {
    width: 24px;
    aspect-ratio: 1/1;
    position: absolute;
    top: -10px;
    right: -10px;
    background: #000000;
    border-radius: 100%;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 40px;

  .input-wrapper {
    flex: 1;
    .radio-input-container {
      display: flex;
    }
    .radio-wrapper-text {
      font-weight: 500;
      font-size: 14px;
      min-width: 210px;
      display: inline-block;
      margin-right: 5px;
    }
    .radio-wrapper-text::after {
      content: "*";
      color: red;
      margin-left: 5px;
    }
    .radio-input {
      display: flex;
      column-gap: 35px;
      min-width: 300px;
      .radio-sub-input {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p {
          font-size: 14px;
        }
      }
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}
.label {
  //color: var(--primary);
  font-size: 14px;
  font-weight: bold;
}
.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 0 0;

  a {
    color: var(--primary);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    .input-wrapper {
      .radio-input-container {
        display: inline-block;
        .radio-wrapper-text {
          margin-bottom: 10px;
        }
      }
      .radio-input {
        //  margin-top: 20px;
        display: flex;
        column-gap: 15px;
        flex-wrap: wrap;
        row-gap: 20px;
      }
    }
  }
}

@media only screen and (max-device-width: 375px) {
  .container {
    padding: 20px !important;
  }
}

@media only screen and (max-device-width: 320px) {
  .wrapper {
    .input-wrapper {
      .radio-input {
        display: flex;
        column-gap: 15px;
        flex-wrap: wrap;
        row-gap: 20px;
        flex-direction: column;
      }
    }
  }
}
.selectable-button-container-style {
  width: 100%;
  float: right;
}
.customeLabel {
  float: left !important;
}
