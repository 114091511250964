.table-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 5px;
  flex: 1;

  .table-action-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 10px;

    .input-field {
      min-height: 45px;
      background: #fafafa;
      border-radius: 5px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      min-width: min-content;
      flex: 1;
    }
    input,
    select,
    button {
      height: 100%;
      font-size: 14px;
      background: #fafafa;
      border-radius: 5px;
      border: none;
    }
    input,
    select {
      flex: 1;
      width: 100%;
      padding: 10px 5px;
    }
    button {
      font-weight: bold;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px 20px;
    }

    .primary-action-button {
      background-color: "#121b5b";
      color: #ffffff;
    }
  }

  .table-wrapper {
    height: 100%;
    background: #ffffff;
    padding: 0 15px;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    .table-sub-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      width: 100%;

      .table-scrollable-content-wrapper {
        flex: 1;
        background: #ffffff;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .table-scrollable-content-sub-wrapper {
          display: table;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  table {
    display: flex;
    flex-direction: column;
    background: #ffffff;

    tr {
      flex: 1;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      padding: 7px 0 !important;
      &:hover {
        background: #efefef;
      }
    }
    thead tr:hover {
      background: #ffffff;
    }
  }

  thead {
    display: flex;
    flex-direction: column;

    tr {
      padding: 5px;
      display: flex;
      flex: 1;
    }
    th {
      display: flex;
      align-items: flex-start;
      padding: 5px 19px;
      font-size: 14px;
      min-height: 60px;
    }
    th {
      width: 150px;
      flex: 1;
      text-align: center;
    }
  }
  tbody {
    display: flex;
    flex-direction: column;
    min-height: min-content;

    tr {
      padding: 5px;
      display: flex;
    }
    td {
      padding: 5px 19px;
      font-size: 14px;
      line-height: 1.6 !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    td {
      width: 150px;
      flex: 1;
    }

    .status {
      padding: 2px 10px;
      border-radius: 20px;
    }
    .action-container {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 20px;
        aspect-ratio: 1/1;
        cursor: pointer;
      }
    }
  }
}

.table-pagination-container {
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .items-count {
    border-radius: 40px;
    background: #d9d9d9;
    padding: 5px 10px;
    font-size: 14px;
  }

  .pagination-action-wrapper {
    border-radius: 40px;
    background: var(--primary);
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 25px;
    button {
      background: transparent;
      border: none;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;

      &:disabled {
        color: #c7c7c7;
      }
    }
    p {
      color: #ffffff;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .table-action-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 10px;
    padding: 15px;

    .input-field {
      width: 100%;
    }
    button {
      width: 100%;
      justify-content: center;
    }

    .primary-action-button {
      background-color: var(--primary);
      color: #ffffff;
    }
  }
  table {
    th {
      font-size: 11px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .table-pagination-container .items-count {
    font-size: 14px;
  }
  .table-pagination-container .pagination-action-wrapper {
    gap: 10px;
    font-size: 10px;
  }
  .table-pagination-container {
    padding: 15px 0 0 0;
    display: flex;
    justify-content: space-between;

    .items-count {
      border-radius: 40px;
      background: #d9d9d9;
      padding: 5px 10px;
      font-size: 14px;
    }

    .pagination-action-wrapper {
      border-radius: 40px;
      background: var(--primary);
      padding: 5px 20px;
      display: flex;
      align-items: center;
      gap: 25px;
      button {
        background: transparent;
        border: none;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;

        &:disabled {
          color: #c7c7c7;
        }
      }
      p {
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .table-pagination-container .pagination-action-wrapper {
    gap: 10px;
    padding: 5px 12px;
    p {
      font-size: 12px;
    }
  }
}
