.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // Material-UI font family
}

.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.filters-wrapper {
  display: flex;
  column-gap: 8px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 8px;
  }
}

.date-change {
  padding: 6px;
  border: 1px solid #ccc;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #ccc;
  }
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 8px;
  }
}

// .selectable-button-container-style {
//   padding: 8px 16px;
// }

.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
}

.excel-button-container-style {
  padding: 6px 14px;
  background-color: var(--excel-green);
}

.table-pagination-container {
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .items-count {
    border-radius: 40px;
    background: #d9d9d9;
    padding: 5px 10px;
    font-size: 14px;
  }

  .pagination-action-wrapper {
    border-radius: 40px;
    background: var(--primary);
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 25px;
    button {
      background: transparent;
      border: none;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;

      &:disabled {
        color: #c7c7c7;
      }
    }
    p {
      color: #ffffff;
      font-size: 14px;
    }
  }
}

.pagination-action-wrapper {
  border-radius: 40px;
  background: var(--primary);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center; /* Center the pagination actions horizontally */
  margin-top: 20px; /* Space between table and pagination */
}

.pagination-action-wrapper button {
  background: transparent;
  border: none;
  color: #c4b5b5;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 16px; /* Add some padding to buttons for better click area */
  border-radius: 20px; /* Rounded corners for buttons */
  transition:
    background 0.3s,
    color 0.3s; /* Smooth transition for background and color */
}

.pagination-action-wrapper button:hover {
  background: #c4b5b5; /* Change background on hover */
  color: #fff; /* Change text color on hover */
}

.pagination-action-wrapper button:disabled {
  color: #c7c7c7;
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.pagination-action-wrapper p {
  color: #998989;
  font-size: 14px;
  margin: 0; /* Remove default margin from p element */
}
