.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  height: 100%;
  .sticky-table,
  .scroll-table {
    td,
    th {
      width: 150px;
      flex: 1;
    }
  }

  .status {
    padding: 2px 10px;
    border-radius: 20px;
    text-align: center;
  }
  .action-container {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 20px;
      aspect-ratio: 1/1;
      cursor: pointer;
    }
  }
}
.stats-container {
  display: flex;
  gap: 25px;

  .stat {
    padding: 18px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #eef1f5;
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .stat-detail-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .stat-label {
      color: #c7c7c7;
      text-transform: uppercase;
    }

    .stat-value {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .stat-icon {
    width: 40px;
    aspect-ratio: 1/1;
  }
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 8px;
  }
}

.filters-container {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.search-mobile {
  background-color: red !important;
  height: 30px;
}

.advance-filter-button-container {
  margin: 10px;
}

.filterDropDown {
  min-width: 200px;
}

.filters-wrapper {
  display: flex;
  column-gap: 8px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 8px;
  }
}

.filters-container-second {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  // border: 1px solid black;
}

.filters-wrapper-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  margin-right: 8px;
  // border: 1px solid black;
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 8px;
  }
}

.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;
}

@media screen and (max-width: 768px) {
  .sticky-table {
    td,
    th {
      width: 100px !important;
    }
  }
}

@media only screen and (max-device-width: 375px) {
  .container {
    // padding: 20px !important;
  }
}



.asssign-container-second {
  // border: 1px solid black;
  display: flex;
  align-items: flex-end;
}

.inspected-container {
  // border: 1px solid red;
  width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 0 10px;
  gap: 10px;
}

.radio-button-label {
  // margin-left: 0px;
  display: flex;
  align-items: center;
  gap: 1px;
}

.assign-wrapper-between {
  // border: 1px solid red;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end; 
  width: 100%; 
  padding-right: 20px;
  border: none;
  border-radius: 4px;
}

.search-action-wrapper-disabled{
  // border: 1px solid black;
  padding: 2px;
  border-radius: 4px;

}


.page-limit-dropdown {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}


.assign-action-button{
  margin-right: 10px;
  height: 40px;
  border: none;
  background-color: #cccccc;
  border-radius: 5px;
  padding: 5px 10px;
  color: #666666;
  font-weight: normal;
  button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }

}