.container {
  display: flex;
  background: transparent;

  button {
    background: var(--primary);
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    padding: 11px 30px;
    // color: #ffffff;
    color: #000;
    border: none;
    cursor: pointer;
  }
}

.transparent {
  background-color: transparent !important;
  color: var(--primary) !important;
  padding: 0 !important;
}

@media screen and (max-width: 425px) {
  .container {
    button {
      padding: 8px 15px;
      font-size: 12px;
    }
  }
}
