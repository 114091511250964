.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.header-wrapper {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.wrapper {
  display: flex;
  column-gap: 30px;
  margin-top: 10px;
}

.input-label {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.boxItem {
  margin-left: 10px;
}

.label {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.select-box {
  margin: 10px 0;
}

.boxes{
  width: 200px;
}

.vehicle-details-container {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 30px;
  row-gap: 30px;
}

.two-tabs-section {
  display: flex;
  column-gap: 30px;
}

.selectable-button-container-style {
  flex: 1;
}

.primary-button-container-style {
  background-color: var(--primary-blue);
  padding: 10px 40px;
}

.vehicle-images-container {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 6px 6px 54px 0 var(--box-shadow);
  flex: 2;
  display: flex;
  padding: 15px;
  flex-direction: column;
}

.submit-cont {
  //border: 1px solid black;
  padding: 10px;
}

.input-checkbox {
  transform: scale(1.3);
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-row {
  display: flex;
  gap: 20px;
}

.images-wrapper {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  column-gap: 30px;

  @media screen and (max-width: 320px) {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }

  @media screen and (max-width: 545px) {
    display: grid;

    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    display: grid;
    justify-content: center;

    align-items: center;
  }
}

.submit-btn {
  // display: flex;
  // justify-content: flex-end;
  padding-top: 1rem;
}
.heading-lable {
  display: flex;
  flex-direction: column;
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 8px;
  }
}
