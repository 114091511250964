.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: 20px;
    flex-direction: row;
  }
  
  @media (max-width: 768px) {
  
    /* You can adjust 768px based on your breakpoints */
    .wrapper {
      flex-direction: column;
      align-items: left;
      margin-left: 0;
    }
  
    .upload-browse {
      margin-left: 0;
      width: 10%;
      height: auto;
      padding-left: 0;
    }
  
    .box {
      width: 10%;
      padding: 20px;
      margin-left: 0px;
      box-shadow: none;
    }
  }
  
  .select-wrapper,
  .input-wrapper,
  .remarks {
    width: 400px;
    text-align: start;
  }
  
  .select-filter {
    width: 100%;
  }
  
  .text {
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
  
  .remark-text {
    margin-top: 40px;
    margin-right: 15px;
  }
  
  .remark-box {
    margin-top: 3px;
    min-height: 95px;
    width: 400px;
    border-radius: 5px;
    // border: solid 1px #efefef;
    text-overflow: ellipsis;
    padding: 5px;
  }
  
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // background: #ffffff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .line {
    border: 1px solid #ebebeb;
    margin: 40px 0;
  }
  
  .form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px;
  }
  
  .top-filters {
    display: flex;
    flex-direction: row;
  }
  
  .label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  a {
    color: var(--primary);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
  
  .upload-browse {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 1400px;
    margin-top: 90px;
    height: 400px;
    padding-left: 40px;
  }
  
  .box {
    display: flex;
    width: 80%;
    padding: 65px 35px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    // background-color: rgb(243, 243, 243);
    background-color: #efeded;
  }
  
  .upload-area-title {
    color: #a5a5a5;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .uploadlabel {
    width: 260px;
    height: 260px;
    background: #18a7ff0d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #18a7ff82;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .uploadlabel span {
    font-size: 70px;
    color: #18a7ff82;
  }
  
  .uploadlabel p {
    color: #18a7ff;
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: 400;
  }
  
  .Uploaded {
    font-size: 18px;
    font-weight: 600;
    color: #363636;
    margin-bottom: 15px;
  }
  
  .showfilebox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 5px 10px;
    border-bottom: solid rgb(177, 177, 177) 2px;
  }
  
  .fileWrapper {
    width: 550px;
    overflow-y: scroll;
    max-height: 220px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .showfilebox .left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .filetype {
    padding: 3px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 3px;
  }
  
  .left h3 {
    font-weight: 600;
    font-size: 15px;
    color: #292f42;
    margin: 0;
  }
  
  .right span {
    color: #18a7ff;
    width: 25px;
    height: 25px;
    font-size: 25px;
    line-height: 25px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .uploaded-file-wrappper {
    max-height: 270px;
    margin-left: 100px;
  }
  
  .back-link-paragraph {
    color: blue;
    cursor: pointer;
  }
  
  .action-back {
    display: flex;
    justify-content: space-between;
  }
  
  .action-back p {
    margin-left: 15px;
  }
  